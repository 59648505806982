<template>
  <div :class="['content', 'index-container',
  {'mobile-download-content':clientWidth<=mobileWidth}]">
    <common-header
      :is-show-menu="true"
      :client-width="clientWidth"
      current-menu="download"
      @openMobileMenu="openMobileMenu"/>
    <div class="wapper">
      <p class="title">资源下载</p>
      <div class="flex">
        <div class="left">
          <p>
            <a href="https://www.microsoft.com/zh-cn" target="_blank">
              <img src="../../assets/imgs/download/icon_software@2x.png" class="first-img">
              <span>微软官网（点击跳转）</span>
            </a>
          </p>
          <p>
            <a href="/app/微软运行库合集.zip" target="_blank">
            <img src="../../assets/imgs/download/icon_yunxingku@2x.png" class="second-img">
            <span>微软运行库合集</span>
            </a>
          </p>
          <p>
            <a href="/app/Wordzitiku.zip" target="_blank">
            <img src="../../assets/imgs/download/icon_fonts@2x.png" class="third-img">
            <span>常用字体库（点击下载）</span>
            </a>
          </p>
        </div>
        <img src="../../assets/imgs/download/pic_ziyuan@2x.png" class="right">
      </div>
    </div>
    <common-footer v-show="clientWidth>mobileWidth"/>
    <mobile-footer v-show="clientWidth<=mobileWidth"/>
    <slide-menu ref="slideMenu"/>
  </div>
</template>
<script>
import CommonHeader from '@components/CommonHeader.vue';
import CommonFooter from '@components/CommonFooter.vue';
import MobileFooter from '@components/MobileFooter.vue';
import SlideMenu from '../../components/Menu.vue';

export default {
  name: 'ResourcesDownload',
  components: {
    CommonHeader,
    CommonFooter,
    MobileFooter,
    SlideMenu,
  },
  data() {
    return {
      clientWidth: 1920,
    };
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    if (this.$route.query.anchor) {
      this.jumpSection(this.$route.query.anchor);
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.clientWidth = document.body.clientWidth;
    },
    openMobileMenu() {
      this.$refs.slideMenu.onOpen();
    },
  },
};
</script>
<style lang="less" scoped>
  @import "../../less/mobileIndex.less";
  .content{
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 80px 0 0;
    .title{
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 36px;
      position: relative;
      text-align: center;
      margin: 95px 0;
      &::after{
        position: absolute;
        content: '';
        width: 120px;
        height: 8px;
        background: #F75959;
        border-radius: 4px;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
        animation: fadeIn 1s 1;
      }
    }
    .flex{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 256px;
      .right{
        width: 384px;
        height: 394px;
      }
      .left{
        margin-right: 268px;
        width: 295px;
        .first-img{
          width: 45px;
          height: 46px;
          margin-right: 29px;
        }
        .second-img{
          width: 45px;
          height: 45px;
          margin-right: 29px;
        }
        .third-img{
          width: 39px;
          height: 48px;
          margin-right: 32px;
        }
        p{
          span{
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 28px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .mobile-download-content{
    .title{
      font-size: 16px;
      margin-bottom: 48px;
      &::after{
        width: 60px;
        height: 4px;
        bottom: -4px;
      }
    }
    .flex{
      flex-direction: column-reverse;
      margin-bottom: 135px;
      .left{
        margin: 41px 0 0 0;
        .first-img,.second-img{
          width: 16px;
          height: 16px;
        }
        .third-img{
          width: 14px;
          height: 16px;
        }
        p{
          span{
            font-size: 12px;
            font-weight: 500;
            color: #000000;
          }
        }
      }
      .right{
        width: 177px;
        height: 181px;
      }
    }
  }

</style>
